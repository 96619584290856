import { LoadStatus } from '../utils/loadStatus';
import { makeAutoObservable } from 'mobx';
import RootStore from './RootStore';
import type { HotelReservationProcessParams } from '../obj-type/obj-type';
import { AxiosInvoker } from '../utils/axiosInvoker';
import HotelApi, { recognizeImage } from '../apis/hotel-api';
import { HotelReservationDto } from '../obj-type/obj-type';
class ReserveRequestOperationStore {
  rootStore: RootStore;
  loadStatus: LoadStatus;

  currentOperateReservation: HotelReservationDto = {
    merchant: {
      proxyHotelStatus: 0
    }
  };
  textDetections = [];
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
    this.loadStatus = new LoadStatus();
  }

  loadReserveRequestDetail = (recId: number) => {
    return HotelApi.loadHotelReservationDetail(recId);
  };

  handleCurrentOperateReservation(data) {
    this.currentOperateReservation = data;
  }
  getRecognizeTexts(imgPath) {
    return recognizeImage(imgPath);
  }
  processHotelReservation = (data: HotelReservationProcessParams) => {
    const recId = this.currentOperateReservation.recId;
    const params = {
      reject: false,
      ...data
    };
    return AxiosInvoker.invoke(this.loadStatus, () => HotelApi.processHotelReservation(params, recId), () => {});
  };
}

export default ReserveRequestOperationStore;
