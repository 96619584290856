import { makeAutoObservable } from 'mobx';
import { LoadStatus } from '../utils/loadStatus';
import { AxiosInvoker } from '../utils/axiosInvoker';
import AuthStore from './AuthStore';
import {
  getProductList,
  updateNewEnabledStatus,
  updateRecommendStatus,
  updateSaleStatus,
  getProductInfo,
  getAllAreas,
  getSupplier,
  getItemAreas,
  createItem,
  updateItem
} from '../apis/product-api';

class ProductListStore {
  products = []; //商品列表
  totalPage = 1; //总共有多少页
  pageNum = 1; //获取第几页
  loadListStatus = new LoadStatus();
  loadUpdateStatus = new LoadStatus();

  productInfo = {};
  allAreas = [];
  supplier = [];
  itemArea = {};
  categories = [];

  loadProductInfoStatus = new LoadStatus();
  loadProductInfoForForm = false;
  loadProductDetailForForm = false;
  submitObj = { page: 1 };

  //暂时不需要
  loadSupplierStatus = new LoadStatus();
  loadItemAreaStatus = new LoadStatus();
  loadCategoriesStatus = new LoadStatus();
  loadAllAreasStatus = new LoadStatus();
  loadCreateStatus = new LoadStatus();
  loadUpdateProductStatus = new LoadStatus();

  constructor() {
    makeAutoObservable(this);
  }

  //商品编辑和新建

  //获取商品详情,填充表单
  fetchProductInfo(id, setValue) {
    const merchantId = this.fetchUserInfo();
    const p1 = getProductInfo(id);
    const p2 = getSupplier({ merchantId });
    return Promise.all([p1, p2]);
  }

  fetchItemAreas(areaCodeId) {
    return getItemAreas(areaCodeId);
  }
  handleProductCreate() {
    this.clearProductInfo();
    //获取所有地区（包含商品分类）
    //获取供应商
    const merchantId = this.fetchUserInfo();
    getAllAreas().then(res => {
      const areas = res.data;
      this.changeAllAreas(areas);
    });
    getSupplier({ merchantId }).then(res => {
      const supplier = res.data.list;
      this.changeSupplier(supplier);
    });
  }

  changeCategories(categories) {
    this.categories = categories;
  }
  changeItemArea(area) {
    this.itemArea = area;
  }
  changeProductInfo(data) {
    this.productInfo = data;
  }
  changeSupplier(supplier) {
    this.supplier = supplier;
  }
  changeAllAreas(areas) {
    this.allAreas = areas;
  }
  clearProductInfo() {
    this.productInfo = {};
    this.allAreas = [];
    this.supplier = [];
    this.itemArea = {};
    this.categories = [];
  }

  // 提交表单， 商品编辑
  handleProductUpdateForm(data) {
    const merchantId = this.fetchUserInfo();
    const recId = this.productInfo.recId;
    const params = {
      recId,
      merchantId,
      costPrice: 1,
      price: 0.01,
      itemAreaId: data.areaCodeId,
      parentItemId: 0, //required
      //todo 运费
      freightType: 1,
      freightTemplateId: undefined,
      uniformFreight: 1.0,
      saleQuantity: 0,
      supplierId: '3',
      name: 'aaaaa',
      slogan: 'sda',
      comboType: 1,

      categoryId: 33,
      deliveryNote: '',
      description: '',
      availableCount: 0,
      hotNum: 0,
      ...data
    };
    // return updateProduct(merchantId, recId, params);
    return updateItem(params);
  }
  //提交表单，创建新商品
  handleProductForm(data) {
    const merchantId = this.fetchUserInfo();
    const params = {
      merchantId,
      slogan: 1,
      costPrice: 1,
      price: 0,
      description: 21,
      availableCount: 0,
      hotNum: 0,
      parentItemId: 0,
      supplierId: 3,
      comboType: 1,
      freightType: 1,
      freightTemplateId: 0,
      uniformFreight: 21,
      saleQuantity: 0,
      itemAreaId: 110100,
      categoryId: 33,
      deliveryNote: 12,
      ...data
    };
    return createItem(params);
  }

  /*
   **分界线
   */
  //获取商品列表
  fetchProductList(submitObj) {
    this.submitObj = Object.assign({}, this.submitObj, submitObj);
    const merchantId = this.fetchUserInfo();
    return AxiosInvoker.invoke(
      this.loadListStatus,
      () => getProductList(merchantId, { ...this.submitObj }),
      res => {
        const { list, pages, pageNum } = res.data;
        this.products = list;
        this.totalPage = pages;
        this.pageNum = pageNum;
      }
    );
  }
  clearProducts() {
    this.products = [];
    this.totalPage = 1;
    this.pageNum = 1;
  }
  changeStatus() {}
  //改变为新品/推荐/售卖状态
  fetchProductStatus(row, name, index) {
    return this.handleProductList(row, name);
  }
  handleProductList(row, name) {
    const status = row[name] ? 0 : 1;
    if (name === 'newEnabled') {
      return updateNewEnabledStatus(row.recId, status);
    }
    if (name === 'recommendEnabled') {
      return updateRecommendStatus(row.recId, status);
    }
    if (name === 'saleStatus') {
      return updateSaleStatus(row.recId, status);
    }
  }
  //获取merchantId
  fetchUserInfo() {
    const authStore = new AuthStore();
    const { merchantId } = authStore.getLoginUser();
    return merchantId;
  }

  //改变loading状态
  changeLoadingStatus(loading, status) {
    this[loading] = status;
  }
}

export default ProductListStore;
