import { makeAutoObservable } from 'mobx';
import { LoadStatus } from '../utils/loadStatus';
import { AxiosInvoker } from '../utils/axiosInvoker';
import AuthStore from './AuthStore';
import { getSkuItems } from '../apis/product-api';

class ProductListStore {
  productSkuList = []; //商品列表
  totalPage = 1; //总共有多少页
  pageNum = 1; //获取第几页

  loadSkuStatus = new LoadStatus();
  constructor() {
    makeAutoObservable(this);
  }

  fetchProductSku({ comboType, ...args }) {
    return AxiosInvoker.invoke(
      this.loadSkuStatus,
      () => {
        return getSkuItems({ comboType, ...args });
      },
      res => {
        this.handleProductSkuList(res.data);
      }
    );
  }
  handleProductSkuList(productSkuList) {
    this.productSkuList = productSkuList;
  }
  //获取merchantId
  fetchUserInfo() {
    const authStore = new AuthStore();
    const { merchantId } = authStore.getLoginUser();
    return merchantId;
  }

  //改变loading状态
  changeLoadingStatus(loading, status) {
    this[loading] = status;
  }
}

export default ProductListStore;
