import { makeAutoObservable } from 'mobx';
import { getMerchantList } from '../apis/merchant-api';
import { AxiosInvoker } from '../utils/axiosInvoker';
import { LoadStatus } from '../utils/loadStatus';
class MerchantSearchStore {
  loadListStatus: LoadStatus = new LoadStatus();
  merchants = []; //商品列表
  totalPage = 1; //总共有多少页
  pageNum = 1; //获取第几页
  inputMerchant = '';
  constructor() {
    makeAutoObservable(this);
  }
  setInputMerchant(merchant) {
    this.inputMerchant = merchant;
  }
  fetchMerchantList(data) {
    // hotelSvr: 1,
    const cpyName = this.inputMerchant;
    const params = { pageSize: 10, cpyName, ...data };
    return AxiosInvoker.invoke(
      this.loadListStatus,
      () => {
        return getMerchantList(params);
      },
      res => {
        const { list, pages, pageNum } = res.data;
        this.totalPage = pages;
        this.pageNum = pageNum === 0 ? 1 : pageNum;
        this.merchants = list;
      }
    );
  }
}

export default MerchantSearchStore;
