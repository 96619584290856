import { RootStore } from './RootStore';
import { makeAutoObservable } from 'mobx';
import AuthStore from './AuthStore';
import moment from 'moment';
import { LoadStatus } from '../utils/loadStatus';
import { HotelRoomSku, HotelSaleStatus } from '../obj-type/obj-type';
class RoomStore {
  rootStore: RootStore;
  saveRoomTypeImgStatus: LoadStatus = new LoadStatus();
  selectedDate = moment(new Date()).format('YYYY-MM-DD');
  showPriceModal = false;
  showRoomModal = false;
  showRoomTypeModal = false;
  showRoomTypePriceModal = false;
  showRoomDelModal = false;
  showRoomSkuDelModal = false;
  showRoomTypeDelModal = false;
  showUpdateRoomTypeModal = false;
  showUpdateRoomSkuSaleStatusModal = false;

  showUpdateRoomSkuModal = false;

  curHotelRoomType;
  curHotelRoomSku: HotelRoomSku = { saleStatus: HotelSaleStatus.NOT_SALE };
  curDatePrice: {};
  dates = [];
  hotelRoomList = [];
  roomSkuList = [];
  roomSkuRecId = 0;
  hotelId;
  priceList = [];

  //loading
  hotelLoadingStatus = false;
  roomLoadingStatus = false;
  canlendarLoadingStatus = false;
  loadHotelRoomTypeStatus: LoadStatus = new LoadStatus();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }
  setCurDatePrice(data) {
    this.curDatePrice = data;
  }
  setCanlendarLoadingStatus(bool) {
    this.canlendarLoadingStatus = bool;
  }
  setShowUpdateRoomSkuSaleStatusModal(bool) {
    this.showUpdateRoomSkuSaleStatusModal = bool;
  }
  setHotelLoadingStatus(bool) {
    this.hotelLoadingStatus = bool;
  }

  setShowUpdateRoomSkuModal(bool) {
    this.showUpdateRoomSkuModal = bool;
  }

  setRoomLoadingStatus(bool) {
    this.roomLoadingStatus = bool;
  }
  setPriceList(list) {
    this.priceList = list;
  }
  getHotelId() {
    const authStore = new AuthStore();
    const { recId: hotelId } = authStore.getHotelId();
    this.setHotelId(hotelId);
  }
  setHotelId(id) {
    this.hotelId = id;
  }
  setRoomSkuRecId(id) {
    this.roomSkuRecId = id;
  }
  setRoomSkuList(list) {
    this.roomSkuList = list;
  }
  setHotelRoomList(list) {
    this.hotelRoomList = list;
  }
  setDates(dates) {
    this.dates = dates;
  }
  setSelectedDate(value) {
    this.selectedDate = value;
  }
  setPriceModal(bool) {
    this.showPriceModal = bool;
  }
  setRoomModal(bool) {
    this.showRoomModal = bool;
  }
  setRoomTypeModal(bool) {
    this.showRoomTypeModal = bool;
  }

  setShowRoomTypeDelModal(bool) {
    this.showRoomTypeDelModal = bool;
  }

  setCurHotelRoomType(data) {
    this.curHotelRoomType = data;
  }

  setCurHotelRoomSku(data) {
    this.curHotelRoomSku = data;
  }

  setRoomTypePriceModal(bool) {
    this.showRoomTypePriceModal = bool;
  }
  setRoomDelModal(bool) {
    this.showRoomDelModal = bool;
  }
  setRoomSkuDelModal(bool) {
    this.showRoomSkuDelModal = bool;
  }

  setShowUpdateRoomTypeModal(bool) {
    this.showUpdateRoomTypeModal = bool;
  }
}

export default RoomStore;
