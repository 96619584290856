import { makeAutoObservable, toJS } from 'mobx';
import AuthStore from './AuthStore';
import { getProductInfo, getSupplier, updateItem, createItem, getSkuItems } from '../apis/product-api';
class ProductSpecComboStore {
  productInfo = {};
  suppliers = [];
  loadInfoStatus = false;
  //子商品明细
  comboSubItems = [];
  collapseOne = false;
  collapseItemSku = false;
  //选择规格sku
  subItemSkuList = [];
  comboType = 1;
  constructor() {
    makeAutoObservable(this);
  }
  clearComboSubItems() {
    this.comboSubItems = [];
  }
  //子商品明细，添加规格，获取某一个的sku
  fetchSubItemSku({ parentItemId }) {
    getSkuItems({ comboType: 1, parentItemId }).then(res => {
      const subItemSkuList = res.data;
      //子商品明细中有的就不展示了
      const ids = [];
      this.comboSubItems.forEach(item => {
        ids.push(item.itemId);
      });
      const newItems = subItemSkuList.filter(item => {
        return !ids.includes(item.recId);
      });
      this.addToSubItemSKuList(newItems);
    });
  }
  addToSubItemSKuList(newItems) {
    this.subItemSkuList = newItems;
  }
  genComboSubItems(items) {
    this.comboSubItems = [].concat(this.comboSubItems, items);
  }

  //编辑
  handleUpdateFormSubmit(data) {
    const merchantId = this.fetchUserInfo();
    const {
      name,
      slogan,
      costPrice,
      price,
      description,
      availableCount,
      hotNum,
      parentItemId,
      supplierId,
      comboType,
      freightType,
      freightTemplateId,
      uniformFreight,
      saleQuantity,
      itemAreaId,
      categoryId,
      deliveryNote
    } = this.productInfo;
    const items = toJS(this.comboSubItems);
    const comboSubitems = [];
    items.forEach((element, index) => {
      comboSubitems[index] = { itemId: element.itemId, itemNum: element.itemNum };
    });
    const info = {
      // recId,
      merchantId,
      name,
      slogan,
      costPrice,
      price,
      description,
      availableCount,
      hotNum,
      parentItemId,
      supplierId,
      comboType,
      freightType,
      freightTemplateId,
      uniformFreight,
      saleQuantity,
      itemAreaId,
      categoryId,
      deliveryNote
    };
    const obj = { ...info, ...data, comboSubitems };
    // return updateProduct(merchantId, recId, info);
    return updateItem(obj);
  }
  // 新建
  handleCreateFormSubmit(data) {
    const merchantId = this.fetchUserInfo();
    const items = toJS(this.comboSubItems);
    const comboSubitems = [];
    items.forEach((element, index) => {
      comboSubitems[index] = { itemId: element.itemId, itemNum: element.itemNum };
    });
    const params = {
      merchantId,
      slogan: 1,
      costPrice: 1,
      price: 0,
      description: 21,
      availableCount: 0,
      hotNum: 0,
      parentItemId: 0,
      supplierId: 3,
      comboType: 1,
      freightType: 1,
      freightTemplateId: 0,
      uniformFreight: 21,
      saleQuantity: 0,
      itemAreaId: 110100,
      categoryId: 33,
      deliveryNote: 12,
      comboSubitems,
      ...data
    };
    return createItem(params);
  }
  fetchProductInfo(itemId) {
    return getProductInfo(itemId);
  }
  fetchSupplier() {
    const merchantId = this.fetchUserInfo();
    return getSupplier({ merchantId });
  }
  setLoading(bool) {
    this.loadInfoStatus = bool;
  }
  handleSupplier(supplier) {
    this.suppliers = supplier;
  }
  handleProductInfo(list) {
    this.productInfo = list;
    this.setComboType(list.comboType);
    if (list.comboSubitems) {
      this.handleComboSubItem(list.comboSubitems);
    }
  }
  setComboType(comboType) {
    this.comboType = comboType;
  }
  handleComboSubItem(comboSubItems) {
    this.comboSubItems = comboSubItems;
  }
  //获取merchantId
  fetchUserInfo() {
    const authStore = new AuthStore();
    const { merchantId } = authStore.getLoginUser();
    return merchantId;
  }
  handleSubItem(row) {
    const items = toJS(this.comboSubItems);
    const index = items.findIndex(item => item.recId === row.recId);
    items[index] = toJS(row);
    this.handleComboSubItem(items);
  }
  //删除子商品明细
  handleRemoveSubItem(e, row) {
    const items = toJS(this.comboSubItems);
    const index = items.findIndex(item => item.recId === row.recId);
    items.splice(index, 1);
    this.handleComboSubItem(items);
  }
  clearProductInfo() {
    this.productInfo = {};
  }
  //弹窗展示隐藏
  handleCollapseOne(bool) {
    this.collapseOne = bool;
  }
  handleCollapseItemSku(bool) {
    this.collapseItemSku = bool;
  }
}

export default ProductSpecComboStore;
