import { AxiosResponse } from 'axios';
import { LoadStatus } from './loadStatus';
import { runInAction } from 'mobx';

const onProcessSuccess = (
  loadStatus: ?LoadStatus,
  resp: AxiosResponse,
  handleResp: AxiosResponse => void
): Promise<AxiosResponse<any>> => {
  runInAction(() => {
    if (loadStatus) {
      loadStatus.httpStatus = resp.status;
      loadStatus.makeSuccess();
    }
    if (handleResp) {
      handleResp(resp);
    }
  });
  return resp;
};

const onProcessFail = (loadStatus: ?LoadStatus, error: any): Promise<AxiosResponse> => {
  runInAction(() => {
    const resp = error.response;
    if (loadStatus) {
      if (resp) {
        loadStatus.httpStatus = resp.status;
      } else {
        loadStatus.makeNetworkErr();
      }
      loadStatus.makeFail();
      console.error(loadStatus);
    }
    if (resp) {
      console.error(resp);
    }
  });
  return Promise.reject(error);
};

const invoke = (
  loadStatus: ?LoadStatus,
  invokeFunc: () => Promise<AxiosResponse>,
  handleResp: ?(AxiosResponse) => void
): Promise<AxiosResponse> => {
  if (loadStatus) {
    loadStatus.makeLoading();
  }
  return invokeFunc()
    .then((resp: AxiosResponse) => {
      return onProcessSuccess(loadStatus, resp, handleResp);
    })
    .catch(error => {
      return onProcessFail(loadStatus, error);
    });
};

export const AxiosInvoker = {
  invoke
};
