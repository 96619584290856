import { makeAutoObservable } from 'mobx';
import {
  loadItemImages,
  createItemImage,
  deleteItemImage,
  exchangeSortFlag,
  updateHeadImg,
  getProductInfo
} from '../apis/product-image-api';
import AuthStore from './AuthStore';
import { toast } from 'react-toastify';
import toastConfig from '../utils/common-utils';
class ProductListStore {
  type = 0;
  avatar = [];
  coverImageList = [];
  descImageList = [];
  headImgId = 0;
  loadStatus = false;
  constructor() {
    makeAutoObservable(this);
  }

  handleDeleteImg(row) {
    this.setLoadStatus(true);
    deleteItemImage(row.recId)
      .then(res => {
        this.handleImages(row.itemId);
        toast.success('删除成功', toastConfig);
      })
      .catch(err => {
        this.setLoadStatus(false);
        toast.error('删除失败', toastConfig);
      });
  }
  uploadImg(itemId) {
    const data = { itemId, file: this.avatar[0].originFile, type: this.type };
    this.setLoadStatus(true);
    createItemImage(data)
      .then(res => {
        toast.success('上传成功', toastConfig);
        this.handleImages(itemId);
      })
      .catch(err => {
        this.loadStatus(false);
        toast.error('上传出错', toastConfig);
      });
  }
  //位置
  handlePosition(list, imageItem, index, direction) {
    let movedImage;
    if (direction === 'down') {
      movedImage = list[index + 1];
    } else if (direction === 'up') {
      movedImage = list[index - 1];
    }
    const tmpSortFlag = imageItem.sortFlag;
    // eslint-disable-next-line no-param-reassign
    imageItem.sortFlag = movedImage.sortFlag;
    movedImage.sortFlag = tmpSortFlag;
    const params = {
      itemId: imageItem.itemId,
      images: [
        { recId: imageItem.recId, sortFlag: imageItem.sortFlag },
        { recId: movedImage.recId, sortFlag: movedImage.sortFlag }
      ]
    };
    this.setLoadStatus(true);
    exchangeSortFlag(params)
      .then(res => {
        this.handleImages(params.itemId);
      })
      .catch(err => {
        this.setLoadStatus(false);
      });
  }

  //设为封面
  handleCoverImg(imageItem) {
    //更新头图
    this.setLoadStatus(true);
    updateHeadImg(imageItem.itemId, imageItem.recId)
      .then(res => {
        this.handleImages(imageItem.itemId);
        toast.success('设置成功', toastConfig);
      })
      .catch(err => {
        this.setLoadStatus(false);
        toast.success('设置失败', toastConfig);
      });
  }

  //处理图片
  handleImages(itemId) {
    //获取头图id
    this.getHeadImgId(itemId).then(res => {
      this.setHeadImgId(res.data.headImgId);
      //获取所有图片
      this.getImage(itemId).then(res => {
        this.setImageList(res.data);
        this.setLoadStatus(false);
      });
    });
  }

  getHeadImgId(itemId) {
    return getProductInfo(itemId);
  }
  setHeadImgId(id) {
    this.headImgId = id;
  }

  getImage(itemId) {
    const merchantId = this.fetchUserInfo();
    this.setLoadStatus(true);
    return loadItemImages(merchantId, itemId);
  }

  setImageList(list) {
    const descImageList = list.filter(item => item.type.value === 1);
    const coverImageList = list.filter(item => item.type.value === 0);

    this.coverImageList = [...coverImageList];
    this.descImageList = [...descImageList];
  }
  setType(type) {
    this.type = type;
  }
  setAvatar(files) {
    this.avatar = files;
  }
  //获取merchantId
  fetchUserInfo() {
    const authStore = new AuthStore();
    const { merchantId } = authStore.getLoginUser();
    return merchantId;
  }
  setLoadStatus(bool) {
    this.loadStatus = bool;
  }
}

export default ProductListStore;
