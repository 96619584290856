import { Spinner } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

const SysLoadingOverlay: any = observer(({ active, text, children }) => {
  return (
    <LoadingOverlay
      active={active}
      text={text}
      spinner={<Spinner type="grow" color="primary" size="lg" />}
      styles={{
        overlay: base => ({
          ...base,
          background: 'rgba(255, 255, 255, 0.7)'
        }),
        content: base => ({
          ...base,
          color: '#000000'
        })
      }}
    >
      {children}
    </LoadingOverlay>
  );
});

SysLoadingOverlay.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  text: PropTypes.string
};

export default SysLoadingOverlay;
