import ReserveRequestListStore from './ReserveRequestListStore';
import MchStore from './MchStore';
import AuthStore from './AuthStore';
import ReserveRequestOperationStore from './ReserveRequestOperationStore';
import AccountInfoStore from './account/AccountInfoStore';
import ProductListStore from './ProductListStore';
import ProductSkuStore from './productSkuStore';
import ProductSpecComboStore from './ProductSpecComboStore';
import ProductImageEditStore from './ProductImageEditStore';
import MerchantSearchStore from './MerchantSearchStore';
import MerchantMapSearchStore from './MerchantMapSearchStore';
import MerchantDetailStore from './MerchantDetailStore';
import RoomStore from './RoomStore';
export class RootStore {
  reserveRequestListStore: ReserveRequestListStore;
  reserveRequestOperationStore: ReserveRequestOperationStore;
  accountInfoStore: AccountInfoStore;
  mchStore: MchStore;
  authStore: AuthStore;
  productListStore: ProductListStore;
  productSkuStore: ProductSkuStore;
  merchantSearchStore: MerchantSearchStore;
  merchantDetailStore: MerchantDetailStore;
  merchantMapSearchStore: MerchantMapSearchStore;
  roomStore: RoomStore;
  constructor() {
    this.reset();
  }

  reset() {
    this.reserveRequestListStore = new ReserveRequestListStore(this);
    this.mchStore = new MchStore(this);
    this.authStore = new AuthStore(this);
    this.reserveRequestOperationStore = new ReserveRequestOperationStore(this);
    this.accountInfoStore = new AccountInfoStore(this);
    this.productListStore = new ProductListStore(this);
    this.productSkuStore = new ProductSkuStore(this);
    this.productSpecComboStore = new ProductSpecComboStore(this);
    this.productImageEditStore = new ProductImageEditStore(this);
    this.merchantSearchStore = new MerchantSearchStore(this);
    this.merchantMapSearchStore = new MerchantMapSearchStore(this);
    this.merchantDetailStore = new MerchantDetailStore(this);
    this.roomStore = new RoomStore(this);
  }
}

export default (new RootStore(): RootStore);
