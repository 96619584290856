import { default as axios } from '../utils/axios-util';
import { BaseAdminUrl } from '../utils/constant';
const productBaseUrl = BaseAdminUrl.COUPON_ADMIN;

//获取sku
export function getSkuItems({ parentItemId, comboType, ...args }) {
  return axios({ url: `${productBaseUrl}/items/${parentItemId}/skus`, params: { comboType, ...args } });
}
//获取商品列表
export function getProductList(merchantId, params) {
  return axios({
    url: `${productBaseUrl}/merchants/${merchantId}/items`,
    params: { merchantId, pageSize: 10, ...params }
  });
}

//更新新品状态
export function updateNewEnabledStatus(recId, newEnabled) {
  return axios({
    url: `${productBaseUrl}/items/${recId}/new-enabled`,
    method: 'put',
    params: { newEnabled }
  });
}

//更新推荐状态
export function updateRecommendStatus(recId, recommendEnabled) {
  return axios({
    url: `${productBaseUrl}/items/${recId}/recommend-enabled`,
    method: 'put',
    params: { recommendEnabled }
  });
}

//更新销售状态
export function updateSaleStatus(recId, saleStatus) {
  return axios({
    url: `${productBaseUrl}/items/${recId}/sale-status`,
    method: 'put',
    params: { saleStatus }
  });
}

//获取商品信息
export function getProductInfo(itemId) {
  return axios.get(`${productBaseUrl}/items/${itemId}`);
}

//获取商品地区
export function getItemAreas(recId) {
  return axios.get(`${productBaseUrl}/item-areas/${recId}`);
}
export function getAllAreas() {
  return axios.get(`${productBaseUrl}/item-areas`);
}

//删除商品
export function deleteProduct(recId, params) {
  return axios({ url: `${productBaseUrl}/items/${recId}`, method: 'put', params });
}

//获取供应商
export function getSupplier(params) {
  return axios({ url: `${productBaseUrl}/supplier`, params });
}

export function createItem(data: any) {
  const fm = new FormData();
  fm.append('merchantId', data.merchantId);
  fm.append('name', data.name);
  fm.append('slogan', data.slogan);
  fm.append('costPrice', data.costPrice);
  fm.append('price', data.price);
  fm.append('description', data.description);
  fm.append('availableCount', data.availableCount);
  fm.append('hotNum', data.hotNum);
  fm.append('parentItemId', data.parentItemId);
  fm.append('supplierId', data.supplierId);
  fm.append('comboType', data.comboType);
  fm.append('freightType', data.freightType);
  fm.append('freightTemplateId', data.freightTemplateId);
  fm.append('uniformFreight', data.uniformFreight);
  fm.append('saleQuantity', data.saleQuantity);
  fm.append('itemAreaId', data.itemAreaId);
  fm.append('categoryId', data.categoryId);
  fm.append('deliveryNote', data.deliveryNote);
  if (data.comboSubitems) {
    fm.append('comboSubitemStr', JSON.stringify(data.comboSubitems));
  }
  if (data.image) {
    fm.append('image', data.image);
  }
  const { merchantId } = data;
  return axios.post(`${productBaseUrl}/merchants/${merchantId}/items`, fm);
}

export function updateItem(data: any) {
  const fm = new FormData();
  fm.append('recId', data.recId);
  fm.append('merchantId', data.merchantId);
  fm.append('name', data.name);
  fm.append('slogan', data.slogan);
  fm.append('costPrice', data.costPrice);
  fm.append('price', data.price);
  fm.append('costPrice', data.costPrice);
  fm.append('description', data.description);
  fm.append('availableCount', data.availableCount);
  fm.append('hotNum', data.hotNum);
  fm.append('parentItemId', data.parentItemId);
  fm.append('supplierId', data.supplierId);
  fm.append('comboType', data.comboType);
  fm.append('freightType', data.freightType);
  fm.append('freightTemplateId', data.freightTemplateId);
  fm.append('uniformFreight', data.uniformFreight);
  fm.append('saleQuantity', data.saleQuantity);
  fm.append('itemAreaId', data.itemAreaId);
  fm.append('categoryId', data.categoryId);
  fm.append('deliveryNote', data.deliveryNote);
  if (data.comboSubitems) {
    fm.append('comboSubitemStr', JSON.stringify(data.comboSubitems));
  }
  if (data.image) {
    fm.append('image', data.image);
  }
  const { recId: itemId } = data;
  return axios.put(`${productBaseUrl}/items/${itemId}`, fm);
}
//更新商品
export function updateProduct(merchantId, recId, params) {
  const image = params.image;
  params.image = undefined;
  return axios({
    url: `${productBaseUrl}/items/${recId}`,
    method: 'put',
    params,
    data: { ...image }
  });
}
//创建商品
export function createProduct(merchantId, params) {
  return axios({
    url: `${productBaseUrl}/merchants/${merchantId}/items`,
    method: 'post',
    params
  });
}
