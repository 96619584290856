import { default as axios } from '../utils/axios-util';
import { BaseAdminUrl } from '../utils/constant';
const productBaseUrl = BaseAdminUrl.COUPON_ADMIN;

//获取图片
export function loadItemImages(merchantId: number, itemId: number) {
  return axios.get(`${productBaseUrl}/items/${itemId}/item-images`, {
    params: {
      itemId
    }
  });
}

//上传图片
export function createItemImage(data) {
  const { itemId, file, type } = data;
  const formData = new FormData();
  formData.append('image', file);
  formData.append('type', type);
  return axios.post(`${productBaseUrl}/items/${itemId}/item-images`, formData);
}

//删除
export function deleteItemImage(recId: number) {
  return axios.delete(`${productBaseUrl}/item-images/${recId}`);
}

//交换图
export function exchangeSortFlag(param: any) {
  const { itemId, images } = param;
  return axios.put(`${productBaseUrl}/items/${itemId}/item-images`, images);
}

//更新头图
export function updateHeadImg(itemId, headImgId) {
  return axios({ url: `${productBaseUrl}/items/${itemId}/head-img`, method: 'put', params: { headImgId } });
}

//获取商品信息
export function getProductInfo(itemId) {
  return axios.get(`${productBaseUrl}/items/${itemId}`);
}
