import { RootStore } from '../RootStore';
import { LoadStatus } from '../../utils/loadStatus';
import { makeAutoObservable } from 'mobx';
import { AxiosInvoker } from '../../utils/axiosInvoker';
import AccountApi from '../../apis/account-api';
import type { WithdrawOrderQueryParams, WithdrawParam } from '../../obj-type/obj-type';
import { AcctDto, WithdrawalOrderDto } from '../../obj-type/obj-type';

class AccountInfoStore {
  rootStore: RootStore;
  loadStatus: LoadStatus = new LoadStatus();
  loadStatus4Withdraw: LoadStatus = new LoadStatus();
  loadStatus4WithdrawPageQuery: LoadStatus = new LoadStatus();

  acctDtos: Array<AcctDto> = [];
  acctDto4Withdraw: AcctDto = new AcctDto();
  total: number = 0;
  pages: number = 1;
  withdrawOrderQueryParams: WithdrawOrderQueryParams = {
    page: 1,
    pageSize: 20
  };
  withdrawOrders: Array<WithdrawalOrderDto> = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  changeWithdrawOrderQueryParams = (params: WithdrawOrderQueryParams) => {
    this.withdrawOrderQueryParams = {
      ...this.withdrawOrderQueryParams,
      ...params
    };
  };

  getAccountInfo = (): Promise => {
    let loginUser = this.rootStore.authStore.getLoginUser();
    return AxiosInvoker.invoke(
      this.loadStatus,
      () => AccountApi.getAccounts(loginUser.merchantId),
      resp => {
        this.acctDtos = resp.data;
      }
    );
  };

  loadAcctByAcctNo = (acctNo: number): void => {
    this.acctDto4Withdraw = {};
    let dtos = this.acctDtos.filter(item => {
      return item.acctNo === acctNo;
    });
    if (dtos.length > 0) {
      this.acctDto4Withdraw = dtos[0];
    }
  };

  beforeApply4Withdraw = () => {
    this.loadStatus4Withdraw.reset();
  };

  apply4Withdraw = (param: WithdrawParam) => {
    return AxiosInvoker.invoke(this.loadStatus4Withdraw, () => AccountApi.withdrawApply(param));
  };

  loadWithdrawOrders = () => {
    return AxiosInvoker.invoke(
      this.loadStatus4WithdrawPageQuery,
      () => AccountApi.getWithdrawOrders(this.withdrawOrderQueryParams),
      resp => {
        this.withdrawOrders = resp.data.list;
      }
    );
  };
}

export default AccountInfoStore;
