import { default as axios } from '../utils/axios-util';
import { BaseAdminUrl } from '../utils/constant';
import type { LoadHotelReservationsParams } from '../obj-type/obj-type';
import AxiosResponse from 'axios';

function loadHotelReservations(params: LoadHotelReservationsParams): Promise<AxiosResponse> {
  return axios.get(`${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations`, {
    params: params
  });
}

function loadHotelReservationDetail(recId: number): Promise<AxiosResponse> {
  return axios.get(`${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations/${recId}`);
}

function processHotelReservation(params: any, recId: number): Promise<AxiosResponse> {
  return axios.put(`${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations/${recId}/process`, params);
}

function distributeReservation(recId: number): Promise<AxiosResponse> {
  return axios.put(`${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations/${recId}/distribution`);
}

function cancelReservation(recId: number, data: any): Promise<AxiosResponse> {
  return axios.put(`${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations/${recId}/cancel`, data);
}

function changeReservationPrice(recId: number, data: any): Promise<AxiosResponse> {
  return axios.put(`${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations/${recId}/changePrice`, data);
}

function refundReservation(recId: number, data: any): Promise<AxiosResponse> {
  return axios.put(`${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations/${recId}/refund`, data);
}

function countHotelReservationStatus(merchantId: number, params: any): Promise<AxiosResponse> {
  return axios.get(`${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations/${merchantId}/count-by-status`, {
    params
  });
}

function grabOrder(params: any): Promise<AxiosResponse> {
  return axios.get(`${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations/grab-order`, {
    params
  });
}

function sendHotelReservationSms(data): Promise<AxiosResponse> {
  return axios({ url: `${BaseAdminUrl.COUPON_ADMIN}/sms/hotel-reservation`, method: 'post', data });
}
export function recognizeImage(imagePath) {
  return axios({
    url: `${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations/image-recognition`,
    params: { imagePath }
  });
}
function changeHotelSettlementPrice({ recId, data }) {
  return axios.put(`${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations/${recId}/changeSettlement`, data);
}
function exportHotelOrders(data: { endDate: 'string', merchantId: number, startDate: 'string' }) {
  return axios({
    url: `${BaseAdminUrl.COUPON_ADMIN}/hotel-reservations/trade-order/export`,
    method: 'post',
    data,
    responseType: 'blob'
  });
}
const HotelApi = {
  loadHotelReservations,
  processHotelReservation,
  distributeReservation,
  cancelReservation,
  refundReservation,
  changeReservationPrice,
  countHotelReservationStatus,
  loadHotelReservationDetail,
  grabOrder,
  sendHotelReservationSms,
  changeHotelSettlementPrice,
  exportHotelOrders
};

export default HotelApi;
