import { LoadStatus } from '../utils/loadStatus';
import { makeAutoObservable } from 'mobx';
import { AxiosInvoker } from '../utils/axiosInvoker';
import HotelApi from '../apis/hotel-api';
import type {
  HotelReservationChangePriceParams,
  HotelReservationRefundParams,
  HotelReservationRejectParams
} from '../obj-type/obj-type';
import { HotelReservationDto, MerchantIntroDto } from '../obj-type/obj-type';
import type { LoadHotelReservationsParams } from '../obj-type/obj-type';
import { ReservationStatus } from '../obj-type/obj-type';
import { RootStore } from './RootStore';
import { AxiosResponse } from 'axios';
import MchApi from '../apis/mch-api';

const WAIT_GRABORDER_RESERVATION_COUNT = 0;

class ReserveRequestListStore {
  rootStore: RootStore;
  loadStatus: LoadStatus = new LoadStatus();
  loadSmsStatus: Boolean = false;
  loadHotelSettlementPriceStatus: LoadStatus = new LoadStatus();
  exportHotelOrdersStatus: LoadStatus = new LoadStatus();
  loadMchInfoStatus: LoadStatus = new LoadStatus();
  mchIntro: MerchantIntroDto = { headImg: {}, imageList: [] };
  list: Array<HotelReservationDto> = [];
  currentOperateReservation: HotelReservationDto = new HotelReservationDto();
  total: number = 0;
  pages: number = 1;
  loadHotelReservationsParams: LoadHotelReservationsParams = {
    reservationStatus: ReservationStatus.PENDING,
    page: 1,
    pageSize: 20,
    merchantId: 0,
    operatorId: 0,
    userMobile: ''
  };
  maxRefundAmount: number = 0;

  hotelReservationPendingCount: number = 0;
  hotelReservationDistributionCount: number = 0;
  hotelReservationWaitGrabCount: number = 0;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  changeHotelSettlementPrice = data => {
    const recId = this.currentOperateReservation.recId;
    return AxiosInvoker.invoke(
      this.loadHotelSettlementPriceStatus,
      () => HotelApi.changeHotelSettlementPrice({ recId, data }),
      resp => {
        console.log(resp.data);
      }
    );
  };
  exportHotelOrders = data => {
    data.merchantId = this.loadHotelReservationsParams.merchantId;
    return AxiosInvoker.invoke(
      this.exportHotelOrdersStatus,
      () => HotelApi.exportHotelOrders(data),
      resp => {
        console.log(resp.data);
      }
    );
  };
  loadHotelReservations = (): Promise<AxiosResponse> => {
    this.loadHotelReservationsParams.merchantId = this.rootStore.authStore.loginUser.merchantId;
    this.loadHotelReservationsParams.operatorId = this.rootStore.authStore.loginUser.recId;
    return AxiosInvoker.invoke(
      this.loadStatus,
      () => HotelApi.loadHotelReservations(this.loadHotelReservationsParams),
      resp => {
        const { list, total, pages } = resp.data;
        this.list = list;
        this.total = total;
        this.pages = pages;
      }
    );
  };

  changeLoadHotelReservationsParams(data: LoadHotelReservationsParams): void {
    this.loadHotelReservationsParams = {
      ...this.loadHotelReservationsParams,
      ...data
    };
  }

  changeCurrentOperateReservation = (reservation: HotelReservationDto) => {
    this.currentOperateReservation = reservation;
    this.maxRefundAmount = this.currentOperateReservation.tradeOrderDto
      ? (
          this.currentOperateReservation.tradeOrderDto.tradeAmount -
          this.currentOperateReservation.tradeOrderDto.refundAmount
        ).toFixed(2)
      : 0;
  };

  loadHotelReservationListByUserMobile = (): Promise<AxiosResponse> => {
    const prarms = {
      page: this.loadHotelReservationsParams.page,
      pageSize: this.loadHotelReservationsParams.pageSize,
      merchantId: this.rootStore.authStore.loginUser.merchantId,
      userMobile: this.loadHotelReservationsParams.userMobile
    };

    return AxiosInvoker.invoke(
      this.loadStatus,
      () => HotelApi.loadHotelReservations(prarms),
      resp => {
        const { list, total, pages } = resp.data;
        this.list = list;
        this.total = total;
        this.pages = pages;
      }
    );
  };

  grabOrder = (): Promise<AxiosResponse> => {
    const params = {
      merchantId: this.rootStore.authStore.loginUser.merchantId
    };
    return AxiosInvoker.invoke(this.loadStatus, () => HotelApi.grabOrder(params), () => {});
  };

  getMchIntro = (): Promise<AxiosResponse> => {
    return AxiosInvoker.invoke(
      this.loadMchInfoStatus,
      () => MchApi.getMchIntro(this.rootStore.authStore.loginUser.merchantId),
      resp => {
        if (!resp.data.hasData) {
          return;
        }
        this.mchIntro = resp.data.data;
      }
    );
  };

  updateHotelReservationStatusCount = (): Promise<AxiosResponse> => {
    const params = {
      operatorId: this.rootStore.authStore.loginUser.recId
    };
    return AxiosInvoker.invoke(
      null,
      () => HotelApi.countHotelReservationStatus(this.rootStore.authStore.loginUser.merchantId, params),
      resp => {
        const data = resp.data;
        this.hotelReservationPendingCount = data[ReservationStatus.PENDING];
        this.hotelReservationDistributionCount = data[ReservationStatus.WAIT_FOR_DISTRIBUTION];
        this.hotelReservationWaitGrabCount = data[WAIT_GRABORDER_RESERVATION_COUNT];
      }
    );
  };

  rejectHotelReservation = (data: HotelReservationRejectParams): Promise<AxiosResponse> => {
    const recId = this.currentOperateReservation.recId;
    const params = {
      reject: true,
      ...data
    };
    return AxiosInvoker.invoke(this.loadStatus, () => HotelApi.processHotelReservation(params, recId), () => {});
  };

  refundHotelReservation = (data: HotelReservationRefundParams): Promise<AxiosResponse> => {
    const recId = this.currentOperateReservation.recId;
    const params = {
      ...data
    };
    return AxiosInvoker.invoke(this.loadStatus, () => HotelApi.refundReservation(recId, params), () => {});
  };

  cancelHotelReservation = (data: HotelReservationRefundParams): Promise<AxiosResponse> => {
    const recId = this.currentOperateReservation.recId;
    const params = {
      ...data
    };
    return AxiosInvoker.invoke(this.loadStatus, () => HotelApi.cancelReservation(recId, params), () => {});
  };

  distributeHotelReservation = (): Promise<AxiosResponse> => {
    const recId = this.currentOperateReservation.recId;
    return AxiosInvoker.invoke(this.loadStatus, () => HotelApi.distributeReservation(recId), () => {});
  };

  changeHotelReservationPirce = (data: HotelReservationChangePriceParams): Promise<AxiosResponse> => {
    const recId = this.currentOperateReservation.recId;
    const params = {
      ...data
    };
    return AxiosInvoker.invoke(this.loadStatus, () => HotelApi.changeReservationPrice(recId, params), () => {});
  };

  fetchHotelReservationSms(data) {
    return HotelApi.sendHotelReservationSms(data);
  }
  handleSmsLoading(loadSmsStatus) {
    this.loadSmsStatus = loadSmsStatus;
  }
}

export default ReserveRequestListStore;
