import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { BaseUrl } from './utils/constant';

const App = () => {
  if (process.env.REACT_APP_ENV === 'test' || process.env.REACT_APP_ENV === 'dev') {
    console.info(`env: ${process.env.REACT_APP_ENV}`);
    console.info(`${BaseUrl.COUPON}`);
    console.info(`${BaseUrl.MERCHANT}`);
    console.info(`${BaseUrl.EASE_PAY}`);
    console.info(`${BaseUrl.EASE_CHECK}`);
  }
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

export default App;
