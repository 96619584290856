import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import { getPageName } from '../helpers/utils';

const MchRoutes = loadable(() => import('./MchRoutes'));

const MchLayout = ({ location }) => {
  const { isFluid, isTopNav, navbarStyle } = useContext(AppContext);

  const isKanban = getPageName('kanban');

  useEffect(() => {
    MchRoutes.preload();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {!isTopNav && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
      <div className="content">
        <NavbarTop />
        <MchRoutes />
      </div>
    </div>
  );
};

MchLayout.propTypes = { location: PropTypes.object.isRequired };

export default MchLayout;
