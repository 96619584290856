/* eslint-disable no-undef */
import { makeAutoObservable } from 'mobx';
import { getCoord, getResearches } from '../apis/merchant-api';

class MerchantMapSearchStore {
  loadMerchantStatus = false;
  inputMerchant = '';
  modalStatus = false;
  itemDetail: {};
  constructor() {
    makeAutoObservable(this);
  }
  setLoadMerchantStatus(bool) {
    this.loadMerchantStatus = bool;
  }
  changeModalStatus(evt) {
    this.modalStatus = !this.modalStatus;
  }
  setItemDetail(detail) {
    this.itemDetail = detail;
  }
  fetchCoord(address) {
    return getCoord(address);
  }
  fetchDistanceMerchant(latitude, longitude) {
    return getResearches(latitude, longitude);
  }
  setInputMerchant(merchant) {
    this.inputMerchant = merchant;
  }
}

export default MerchantMapSearchStore;
