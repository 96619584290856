import axios from 'axios';
import { USER_TOKEN } from './constant';
import JSONBig from 'json-bigint';
import CommonUtil from './common-utils';

const sysAxios = axios.create({
  transformResponse: data => {
    if (CommonUtil.isJson(data)) {
      return JSONBig.parse(data);
    }
    return data;
  }
});

sysAxios.interceptors.request.use(
  config => {
    const token = CommonUtil.getStorageItem(USER_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
sysAxios.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default sysAxios;
