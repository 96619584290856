import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { toast } from 'react-toastify';
import { Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import SysLoadingOverlay from '../../../../components/system/SysLoadingOverlay';
import StoreContext from '../../../../context/StoreContext';
import CommonUtil from '../../../../utils/common-utils';

const Login: any = observer(() => (
  <>
    <Row className="text-left justify-content-between">
      <Col xs="auto">
        <h5>登 录</h5>
      </Col>
      <Col xs="auto">
        {/*<p className="fs--1 text-600">*/}
        {/*  <Link to="#">创建账号</Link>*/}
        {/*</p>*/}
      </Col>
    </Row>
    <LoginForm />
  </>
));

const LoginForm = observer(() => {
  // State
  const [userTel, setUserTel] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [canRedirect, setCanRedirect] = useState(false);
  const [collapseOne, setCollapseOne] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { authStore } = useContext(StoreContext);

  const handleSubmit = e => {
    e.preventDefault();
    authStore
      .login({ userTel, userPwd: password, terminalDevice: 'merchant_mini' })
      .then(() => {
        toast.success(`${userTel} 登录成功`);
        setCanRedirect(true);
      })
      .catch(error => {
        if (CommonUtil.checkError(error) === CommonUtil.ERR_NETWORK) {
          setErrorMsg(`连接服务器失败`);
          setCollapseOne(true);
          return;
        }
        if (CommonUtil.checkError(error) === CommonUtil.ERR_SERVER) {
          const resp = error.response;
          setErrorMsg(`服务错误 - ${resp.status}`);
          setCollapseOne(true);
          return;
        }
        setErrorMsg(`用户名或密码错误`);
        setCollapseOne(true);
      });
  };

  useEffect(() => {
    setIsDisabled(!userTel || !password);
  }, [userTel, password]);

  return (
    <>
      {canRedirect ? (
        <Redirect to="/mch/info" />
      ) : (
        <>
          <SysLoadingOverlay active={authStore.loadStatus.isLoading()} text="登录中 ... ...">
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  placeholder="用户名"
                  value={userTel}
                  onChange={({ target }) => setUserTel(target.value)}
                  type="text"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  placeholder="密码"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                  type="password"
                />
              </FormGroup>
              {/*<Row className="justify-content-between align-items-center">*/}
              {/*  <Col xs="auto">*/}
              {/*    <Link className="fs--1" to={`/mch/staffs/forget-password`}>*/}
              {/*      忘记密码*/}
              {/*    </Link>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <FormGroup>
                <Button color="primary" block className="mt-3" disabled={isDisabled}>
                  登 录
                </Button>
              </FormGroup>
            </Form>
          </SysLoadingOverlay>
          <Modal isOpen={collapseOne} toggle={() => setCollapseOne(!collapseOne)} centered>
            <ModalHeader>登录失败</ModalHeader>
            <ModalBody>{errorMsg}</ModalBody>
            <ModalFooter>
              <Button onClick={() => setCollapseOne(!collapseOne)}>关闭</Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
});

export default Login;
