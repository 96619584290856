export const USER_TOKEN = 'login_token';
export const USER_INFO = 'login_info';
export const HOTEL_ID = 'hotel_id';

export const BaseUrl = {
  COUPON: process.env.REACT_APP_COUPON_BASE_URL,
  MERCHANT: process.env.REACT_APP_MERCHANT_BASE_URL,
  EASE_PAY: process.env.REACT_APP_EASE_PAY_BASE_URL,
  EASE_CHECK: process.env.REACT_APP_EASE_CHECK_BASE_URL
};

export const BaseAdminUrl = {
  MERCHANT_ADMIN: `${BaseUrl.MERCHANT}/v1/merchant/management`,
  PAY_ADMIN: `${BaseUrl.EASE_PAY}/v1/payment/management`,
  COUPON_ADMIN: `${BaseUrl.COUPON}/v1/marketing/management`
};
