import { RootStore } from './RootStore';
import { makeAutoObservable } from 'mobx';
import MchApi from '../apis/mch-api';
import { AxiosResponse } from 'axios';
import { MerchantIntroDto } from '../obj-type/obj-type';
import { LoadStatus } from '../utils/loadStatus';
import { AxiosInvoker } from '../utils/axiosInvoker';
import type { SaveMchIntroParams } from '../obj-type/obj-type';

class MchStore {
  rootStore: RootStore;
  loadMchInfoStatus: LoadStatus = new LoadStatus();
  saveMchInfoStatus: LoadStatus = new LoadStatus();
  saveMchImgStatus: LoadStatus = new LoadStatus();
  processMchImgStatus: LoadStatus = new LoadStatus();
  mchIntro: MerchantIntroDto = { city: {}, headImg: {}, imageList: [] };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  getMchIntro = (mchId: number): Promise<AxiosResponse> => {
    return AxiosInvoker.invoke(
      this.loadMchInfoStatus,
      () => MchApi.getMchIntro(mchId),
      resp => {
        if (!resp.data.hasData) {
          return;
        }
        this.mchIntro = resp.data.data;
      }
    );
  };

  saveMchIntro = (data: SaveMchIntroParams): Promise<AxiosResponse> => {
    return AxiosInvoker.invoke(
      this.saveMchInfoStatus,
      () => MchApi.saveMchIntro(data),
      () => {
        this.mchIntro = { ...this.mchIntro, ...data };
      }
    );
  };

  saveMchImg = (merchantId: number, file: any) => {
    return AxiosInvoker.invoke(this.saveMchImgStatus, () => MchApi.createMchImg(merchantId, file), () => {});
  };

  deleteMchImg = (mchId: number, imgId: number) => {
    return AxiosInvoker.invoke(this.processMchImgStatus, () => MchApi.deleteMchImg(mchId, imgId), () => {});
  };

  updateHeadImg = (mchId: number, imgId: number) => {
    return AxiosInvoker.invoke(this.processMchImgStatus, () => MchApi.updateHeadImg(mchId, imgId), () => {});
  };

  updateImages = (mchId: number, images: number) => {
    return AxiosInvoker.invoke(this.processMchImgStatus, () => MchApi.updateImages(mchId, images), () => {});
  };
}

export default MchStore;
