import { default as axios } from '../utils/axios-util';
import { BaseAdminUrl } from '../utils/constant';
import { WithdrawOrderQueryParams, WithdrawParam } from '../obj-type/obj-type';
import AxiosResponse from 'axios';

function getAccounts(): Promise<AxiosResponse> {
  return axios.get(`${BaseAdminUrl.PAY_ADMIN}/accounts`);
}

function withdrawApply(param: WithdrawParam): Promise<AxiosResponse> {
  return axios.post(`${BaseAdminUrl.PAY_ADMIN}/withdrawals`, param);
}

function getWithdrawOrders(param: WithdrawOrderQueryParams): Promise<AxiosResponse> {
  return axios.get(`${BaseAdminUrl.PAY_ADMIN}/withdrawals`, {
    params: param
  });
}

const AccountApi = {
  getAccounts,
  withdrawApply,
  getWithdrawOrders
};

export default AccountApi;
