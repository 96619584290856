import { makeAutoObservable } from 'mobx';

const LoadStatusCons = {
  INIT: 0,
  LOADING: 1,
  SUCCESS: 2,
  FAIL: 3
};

class LoadStatus {
  status:
    | typeof LoadStatusCons.INIT
    | typeof LoadStatusCons.LOADING
    | typeof LoadStatusCons.SUCCESS
    | typeof LoadStatusCons.FAIL = LoadStatusCons.INIT;

  httpStatus: number = 0;

  constructor() {
    makeAutoObservable(this);
    this.status = LoadStatusCons.INIT;
    this.httpStatus = 0;
  }

  makeLoading = (): void => {
    this.httpStatus = 0;
    this.status = LoadStatusCons.LOADING;
  };

  makeSuccess = (): void => {
    this.status = LoadStatusCons.SUCCESS;
  };

  makeFail = (): void => {
    this.status = LoadStatusCons.FAIL;
  };

  makeNetworkErr = (): void => {
    this.httpStatus = -1;
  };

  reset = (): void => {
    this.status = LoadStatusCons.INIT;
    this.httpStatus = 0;
  };

  isLoading = (): boolean => {
    return this.status === LoadStatusCons.LOADING;
  };

  isSuccess = (): boolean => {
    return this.status === LoadStatusCons.SUCCESS;
  };

  isFail = (): boolean => {
    return this.status === LoadStatusCons.FAIL;
  };

  isNeedLogin = (): boolean => {
    return this.httpStatus === 401;
  };

  isNeedAuth = (): boolean => {
    return this.httpStatus === 403;
  };
}

export { LoadStatus };
