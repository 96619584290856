import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './helpers/initFA';
import rootStore from './store/RootStore';
import StoreContext from './context/StoreContext';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
import 'antd/dist/antd.css';
import { ConfigProvider } from 'antd';
moment.locale('zh-cn');
ReactDOM.render(
  <StoreContext.Provider value={rootStore}>
    <ConfigProvider locale={zhCN}>
      <Main>
        <App />
      </Main>
    </ConfigProvider>
  </StoreContext.Provider>,
  document.getElementById('main')
);
