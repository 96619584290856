import { default as axios } from '../utils/axios-util';
import { BaseAdminUrl } from '../utils/constant';
import AxiosResponse from 'axios';
import type { LoginParams } from '../obj-type/obj-type';

function login(data: LoginParams): Promise<AxiosResponse> {
  return axios.post(`${BaseAdminUrl.MERCHANT_ADMIN}/auth`, data);
}

function logout(): Promise<AxiosResponse> {}

const MchUserApi = {
  login,
  logout
};

export default MchUserApi;
