import { default as axios } from '../utils/axios-util';
import { BaseAdminUrl } from '../utils/constant';
const productBaseUrl = BaseAdminUrl.COUPON_ADMIN;

//获取商户列表
export function getMerchantList(params) {
  //TODO
  // hotelSvr: 1,
  return axios({ url: `${productBaseUrl}/merchants`, params: { hotelSvr: 1, ...params } });
}
//获取商户详细信息
export function getMerchantInfo(merchantId) {
  return axios.get(`${productBaseUrl}/merchant-intros/${merchantId}`);
}

// /geos/geocoder 地址解析
export function getCoord(address) {
  return axios({ url: `${productBaseUrl}/geos/geocoder`, params: { address } });
}

// /merchants/researches 按距离检索
export function getResearches(latitude, longitude) {
  return axios({ url: `${productBaseUrl}/merchants/researches`, params: { latitude, longitude, distance: 10000 } });
}
