export const mchRoutes = {
  name: '商户中心',
  to: '/mch',
  exact: true,
  children: [
    {
      to: '/mch/info',
      name: '商户信息',
      exact: true
    }
    // {
    //   to: '/mch/staffs',
    //   name: '员工管理',
    //   exact: true
    // }
  ]
};

export const accountRoutes = {
  name: '资金中心',
  to: '/mch/account',
  exact: true,
  children: [
    {
      to: '/mch/account',
      name: '账户资金',
      exact: true
    }
  ]
};

// export const productRoutes = {
//   name: '商品管理',
//   to: '/mch/product/list',
//   exact: true,
//   children: [{ to: '/mch/product/list', name: '商品列表', exact: true }]
// };

export const hotelRoutes = {
  name: '酒店中心',
  children: [
    {
      name: '订单管理',
      to: '/mch/hotel/reserve-request-list',
      exact: true
    },
    {
      name: '房间管理',
      to: '/mch/hotel-set/hotel-detail',
      exact: true
      // children: [{ to: '/mch/hotel-set/hotel-detail', name: '房间管理', exact: true }]
    }
  ]
};

export default [
  mchRoutes,
  accountRoutes,
  // productRoutes,
  hotelRoutes
];
