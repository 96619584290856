import { default as axios } from '../utils/axios-util';
import { BaseAdminUrl } from '../utils/constant';
import AxiosResponse from 'axios';

function getMchIntro(mchId: number): Promise<AxiosResponse> {
  return axios.get(`${BaseAdminUrl.COUPON_ADMIN}/merchant-intros/${mchId}`);
}

function saveMchIntro(data: {
  merchantId: number,
  mobile: string,
  refundRule: string,
  description: string,
  featureDesc: string
}): Promise<AxiosResponse> {
  return axios.post(`${BaseAdminUrl.COUPON_ADMIN}/merchant-intros`, data);
}

function createMchImg(mchId: number, file: any): Promise<AxiosResponse> {
  const formData = new FormData();
  formData.append('image', file);
  return axios.post(`${BaseAdminUrl.COUPON_ADMIN}/merchant-intros/${mchId}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

function deleteMchImg(mchId: number, imgId: number): Promise<AxiosResponse> {
  return axios.delete(`${BaseAdminUrl.COUPON_ADMIN}/images-merchant-intro/${imgId}`);
}

function updateHeadImg(mchId: number, imgId: number): Promise<AxiosResponse> {
  return axios.patch(`${BaseAdminUrl.COUPON_ADMIN}/merchant-intros/${mchId}`, { headImageId: imgId });
}

function updateImages(mchId: number, images: any) {
  return axios.put(`${BaseAdminUrl.COUPON_ADMIN}/merchant-intros/${mchId}/images`, images);
}

const MchApi = {
  getMchIntro,
  saveMchIntro,
  createMchImg,
  deleteMchImg,
  updateHeadImg,
  updateImages
};

export default MchApi;
