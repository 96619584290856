import { makeAutoObservable } from 'mobx';
import { getMerchantInfo } from '../apis/merchant-api';
import { AxiosInvoker } from '../utils/axiosInvoker';
import { LoadStatus } from '../utils/loadStatus';
class MerchantDetailStore {
  loadDetailStatus: LoadStatus = new LoadStatus();
  merchantDetail = {};
  constructor() {
    makeAutoObservable(this);
  }
  fetchMerchantInfo(merchantId) {
    AxiosInvoker.invoke(
      this.loadDetailStatus,
      () => {
        return getMerchantInfo(merchantId);
      },
      res => {
        this.merchantDetail = res.data.data;
      }
    );
  }
}

export default MerchantDetailStore;
