import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import ErrorLayout from './ErrorLayout';
import MchLayout from './MchLayout';
import AuthBasicLayout from './AuthBasicLayout';
import CheckAuth from './CheckAuth';

const Layout = () => {
  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/mch/staffs/auth" component={AuthBasicLayout} />
        <Route path="/mch" component={MchLayout} />
        <Route path="/" exact component={CheckAuth} />
      </Switch>
      <ToastContainer
        transition={Fade}
        autoClose={2000}
        closeButton={<CloseButton />}
        position={toast.POSITION.TOP_CENTER}
      />
    </Router>
  );
};

export default Layout;
