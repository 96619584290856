import JSONBig from 'json-bigint';

export const isIterableArray = array => Array.isArray(array) && !!array.length;

export const isNull = (value: any): boolean => value === null;
export const isUndefined = (value: any): boolean => typeof value === 'undefined';
export const isNullOrUndefined = (value: any): boolean => isNull(value) || isUndefined(value);

const ERR_NO = 0;
const ERR_NETWORK = 1;
const ERR_SERVER = 2;

/**
 * check value is blank string
 * @param {string} value
 */
export const isBlank = (value: string): boolean => typeof value === 'string' && value === '';

function isJson(str: any): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function setStorageItem(key: string, value: string | Object) {
  let data;
  if (typeof value === 'string') {
    data = value;
  } else {
    data = JSONBig.stringify(value);
  }
  localStorage.setItem(key, data);
}

function toObject(json: string): Object {
  return JSONBig.parse(json);
}

function getStorageItem(key: string): ?string {
  const v = localStorage.getItem(key);
  if (v) {
    return v;
  }
  return null;
}

function deleteStorageItem(key: string) {
  localStorage.removeItem(key);
}

function checkServerErr(httpStatusCode: number): boolean {
  return httpStatusCode >= 500 && httpStatusCode < 600;
}

/**
 * 判断网络或服务器是否出问题
 * @param error
 * @returns number {ERR_NO | ERR_NETWORK | }
 */
function checkError(error: any): ERR_NO | ERR_NETWORK | ERR_SERVER {
  const resp = error.response;
  if (resp) {
    const v = checkServerErr(resp.status);
    if (v) {
      return ERR_SERVER;
    }
    return ERR_NO;
  }
  return ERR_NETWORK;
}

export function judgeStatus(key) {
  const statusObj = {
    '0': '否',
    '1': '是'
  };
  return statusObj[key];
}

export const toastConfig = {
  position: 'top-center',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};
const CommonUtil = {
  isJson,
  isIterableArray,
  setStorageItem,
  getStorageItem,
  deleteStorageItem,
  toObject,
  checkServerErr,
  checkError,
  ERR_NO,
  ERR_NETWORK,
  ERR_SERVER
};

export default CommonUtil;
