import { LoadStatus } from '../utils/loadStatus';
import { AxiosResponse } from 'axios';

export const EntityTypeCons = {
  /**
   * 机构
   */
  INST: 0,
  /**
   * 商户
   */
  MCH: 1,
  /**
   * 用户
   */
  PERSON: 2,
  /**
   * 供应商
   */
  SUPPLIER: 3,
  /**
   * 渠道
   */
  CHANNEL: 4
};

export const TradeOrderFeeType = {
  /**
   *  商品花费
   */
  GOOD_COST: 1,
  /**
   *  运费
   */
  FREIGHT: 2,

  /**
   *  优惠券抵扣金额
   */
  COUPON: 3,

  /**
   *  活动优惠金额
   */
  ACTIVITY: 4,

  /**
   *  积分抵扣金额
   */
  POINTS: 5,
  /**
   *  红包现金抵扣
   */
  REDPACK: 6,
  /**
   *  酒店商户折扣
   */
  HOTEL_DISCOUNT: 7,
  /**
   *  酒店商户(代理商户)减免
   */
  HOTEL_DEDUCTION: 8,
  /**
   * 未知类型
   */
  UNKNOWN: 0
};

export const AcctPropertyCons = {
  /**
   * 普通账户
   */
  NORMAL: 0,

  /**
   * 手续费账户
   */
  FEE: 1,

  /**
   * 分润账户
   */
  PROFIT: 2
};

export const ReservationStatus = {
  /**
   * 未知
   */
  UNKNOWN: 0,

  /**
   * 待处理
   */
  PENDING: 1,

  /**
   * 已支付待分配
   */
  WAIT_FOR_DISTRIBUTION: 2,

  /**
   * 待支付
   */
  WAIT_FOR_PAY: 3,
  /**
   * 预定成功
   */
  SUCCESS: 4,

  /**
   * 已取消
   */
  CANCEL: 5
};

export const ProxyHotelStatus = {
  /**
   * 非代理酒店"
   */
  NOT_PROXY: 0,

  /**
   * 代理外部酒店
   */
  PROXY_EXTERNAL_HOTEL: 1
};

export const HotelSaleStatus = {
  /**
   * 非售卖"
   */
  NOT_SALE: 0,

  /**
   * 售卖
   */
  SALE: 1
};

export const GrabOrderStatus = {
  /**
   * 不抢单
   */
  NOT_GRAB: 0,
  /**
   * 多人抢单
   */
  MULTI_GRAB_ORDER: 1
};

export type EntityType = {
  /**
   * EntityTypeCons
   */
  code: number,
  value: string
};

export type AcctProperty = {
  /**
   * AcctPropertyCons
   */
  code: number,
  value: string
};
export const WithdrawStatus = {
  /**
   * 待处理
   */
  APPLY_INIT: 0,
  /**
   * 申请通过
   */
  APPLY_PASS: 1,
  /**
   * 账务余额已扣减, 待审核
   */
  ACCOUNT_BALANCE_DEDUCTED: 2,
  /**
   * 请求第三方成功，待查询结果
   */
  REQUEST_THIRD_SUCEESS: 3,
  /**
   * 提现成功
   */
  SUCCESS: 4,
  /**
   * 提现失败(可重试)
   */
  REPAIRABLE_FAIL: 5,
  /**
   * 提现失败(不可重试)
   */
  UNREPAIRABLE_FAIL: 6,
  /**
   * 已撤销
   */
  APPLY_CANCEL: 7,
  /**
   * 审核通过，待调用第三方提现服务
   */
  APPLY_PASS_WAIT_REQUEST_THIRD_PART: 8,
  /**
   * 提现申请被拒绝
   */
  APPLY_REFUSED: 9
};

export class RemoteResponse {
  status: number;
  errorCode: number;
  message: string;
}

export class MchImage {
  recId: number;
  sortFlag: number;
  absImageUrl: string;
  imageUrl: string;
  absImageUrlSmall: string;
  absImageUrlMiddle: string;
  absImageUrlBig: string;
  createDate: string;
}

export class Area {
  areaCodeId: number;
  area: string;
}

export class LoginUser {
  recId: number;
  userName: string;
  merchantId: number;
  cpyName: string;
}

export class HotelTradeOrderDetailDto {
  orderId: string;
  amount: number;
  roomType: string;
  roomNum: number;
  beginDate: string;
  endDate: string;
}

export class FeeDetailDto {
  fee: number;
  feeType: number;
}

export class TradeOrderDto {
  tradeAmount: number;
  originTradeAmount: number;
  refundAmount: number;
  tradeAmountReceivable: number;
  hotelTradeOrderDetailDto: HotelTradeOrderDetailDto;
  feeDetails: Array<FeeDetailDto>;
}

export class HotelReserveNegotiationDto {
  recId: number;
  reservationId: number;
  reservationStatus: number;
  remarks: string;
  imageUrl: string;
  createDate: string;
  absImageUrl: string;
  absImageUrlSmall: string;
  absImageUrlMiddle: string;
  absImageUrlBig: string;
}

export class MerchantIntroDto {
  merchantId: number;
  merchantName: string;
  address: string;
  headImgId: number;
  minPrice: number;
  description: string;
  createDate: string;
  slogan: string;
  score: number;
  refundRule: string;
  mobile: string;
  email: string;
  invoiceDesc: string;
  headImg: MchImage;
  imageList: Array<MchImage>;
  grabOrderStatus: number;
  proxyHotelStatus: number;
  area: string;
}

export class HotelReservationDto {
  recId: number;
  userId: number;
  merchantId: number;
  orderId: string;
  userName: string;
  userMobile: string;
  beginDate: string;
  endDate: string;
  reservationStatus: number;
  createDate: string;
  operatorId: number;
  hotelName: string;
  hotelMobile: string;
  hotelAddress: string;
  areaCodeId: number;
  area: string;
  isRefund: number;
  hotelTradeOrderDetailDto: HotelTradeOrderDetailDto;
  tradeOrderDto: TradeOrderDto;
  negotiationDtos: Array<HotelReserveNegotiationDto>;
  absImageUrlBig: string;
  absImageUrlMiddle: string;
  absImageUrlSmall: string;
  absImageUrl: string;
  merchant: MerchantIntroDto;
}

export class WithdrawalOrderDto {
  recId: number;
  entityId: number;
  entityType: number;
  entityProperty: number;
  withdrawalOrderId: string;
  amount: number;
  type: number;
  userName: string;
  encBankNo: string;
  encTrueName: string;
  bankCode: string;
  aliPayNo: string;
  aliPayCheckName: string;
  status: number;
  createDate: string;
  notice: string;
  errorCode: string;
  errorMsg: string;
}

// type

export type LoadHotelReservationsParams = {
  page: number,
  pageSize: number,
  merchantId: number,
  reservationStatus: number,
  userMobile?: string,
  operatorId?: number
};

export class RemoteInvokeResult {
  loadStatus: LoadStatus;
  promise: Promise<AxiosResponse>;
}

export type WithdrawParam = {
  /**
   * AcctPropertyCons
   */
  entityProperty: number,
  amount: number
};

export class AcctDto {
  acctNo: number;
  balance: number;
  entityId: number;
  freezeAmount: number;
  openBalance: number;
  acctProperty: AcctProperty;
  type: EntityType;
}

export class HotelRoomSku {
  saleStatus: HotelSaleStatus;
}

export type HotelReservationRefundParams = {
  refundAmount: number,
  remarks: string
};

export type HotelReservationRejectParams = {
  remarks: string,
  reject: Boolean
};

export type HotelReservationChangePriceParams = {
  price: number,
  remarks: string
};

export type HotelReservationProcessParams = {
  hotelName: string,
  hotelMobile: string,
  hotelAddress: string,
  roomNum: number,
  roomType: string,
  amount: number,
  remarks: string,
  proxyDiscount: number,
  hotelDiscount: number
};

export type LoginParams = {
  userTel: string,
  userPwd: string,
  terminalDevice: ?string
};

export type WithdrawOrderQueryParams = {
  page: number,
  pageSize: number
};

export type SaveMchIntroParams = {
  mobile: string,
  refundRule: string,
  description: string
};
