import React, { useContext } from 'react';
import StoreContext from '../../../../context/StoreContext';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

const Logout = () => {
  const { authStore } = useContext(StoreContext);
  authStore.logout();
  toast.success('已退出登录');
  return <Redirect to="/" />;
};

export default Logout;
