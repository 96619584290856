import { default as axios } from '../utils/axios-util';
import { BaseAdminUrl } from '../utils/constant';
// import { AxiosInvoker } from '../utils/axiosInvoker';
// import MchApi from './mch-api';

//房型
export function getHotelRoomType(hotelId, params = {}) {
  // page;
  // integer($int32)(query);
  // 页数;

  // pageSize;
  // integer($int32)(query);
  // 每页大小;

  // startOffset;
  // integer($int32)(query);
  return axios.get(`${BaseAdminUrl.COUPON_ADMIN}/hotels/${hotelId}/room-types`, {
    params: params
  });
}
// create hotel room type
export function createHotelRoomType(hotelId, hotelRoomType) {
  return axios({
    url: `${BaseAdminUrl.COUPON_ADMIN}/hotels/${hotelId}/room-types`,
    method: 'post',
    data: {
      ...hotelRoomType
    }
  });
}

export function updateRoomType(hotelId, hotelRoomType) {
  return axios({
    url: `${BaseAdminUrl.COUPON_ADMIN}/hotels/${hotelId}/room-types/${hotelRoomType.recId}`,
    method: 'put',
    data: {
      ...hotelRoomType
    }
  });
}

export function uploadHotelRoomTypeImg(hotelId: number, roomTypeId: number, file: any) {
  const formData = new FormData();
  formData.append('image', file);
  console.log(hotelId, roomTypeId);
  return axios.post(`${BaseAdminUrl.COUPON_ADMIN}/hotels/${hotelId}/room-types/${roomTypeId}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// delete hotel room type

export function deleteHotelRoomType(hotelId, recId) {
  return axios.delete(`${BaseAdminUrl.COUPON_ADMIN}/hotels/${hotelId}/room-types/${recId}`);
}

//get sku
export function getRoomSkus(hotelId, recId, params = {}) {
  return axios.get(`${BaseAdminUrl.COUPON_ADMIN}/hotels/${hotelId}/room-types/${recId}/room-skus`, {
    params: params
  });
}

//post sku
export function createRoomSku(hotelId, hotelRoomSku) {
  return axios({
    url: `${BaseAdminUrl.COUPON_ADMIN}/hotels/${hotelId}/room-skus`,
    method: 'post',
    data: {
      ...hotelRoomSku
    }
  });
}

export function updateRoomSku(hotelId, hotelRoomSku) {
  return axios({
    url: `${BaseAdminUrl.COUPON_ADMIN}/hotels/${hotelId}/room-skus/${hotelRoomSku.recId}`,
    method: 'put',
    data: {
      ...hotelRoomSku
    }
  });
}
//delete sku
export function deleteRoomSku(hotelId, recId) {
  return axios.delete(`${BaseAdminUrl.COUPON_ADMIN}/hotels/${hotelId}/room-type-skus/${recId}`);
}
//get price
export function getRoomSkuPrices(hotelId, recId, params) {
  return axios.get(`${BaseAdminUrl.COUPON_ADMIN}/hotels/${hotelId}/room-type-skus/${recId}/prices`, { params });
}
//put price
export function createRoomSkuPrice(hotelId, recId, model) {
  return axios({
    url: `${BaseAdminUrl.COUPON_ADMIN}/hotels/${hotelId}/room-type-skus/${recId}/prices`,
    method: 'put',
    data: {
      ...model
    }
  });
}
//query hotel id
export function queryHotelId(merchantId) {
  return axios.get(`${BaseAdminUrl.COUPON_ADMIN}/hotels/query-by-merchant/${merchantId}`);
}
