import * as React from 'react';
import { useContext } from 'react';
import StoreContext from '../context/StoreContext';
import { Redirect } from 'react-router-dom';

const CheckAuth = () => {
  const { authStore } = useContext(StoreContext);

  if (authStore.hasToken()) {
    return <Redirect to="/mch/info" />;
  }
  return <Redirect to="/mch/staffs/auth/login" />;
};

export default CheckAuth;
