import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import MchUserApi from '../apis/mch-user-api';
import AxiosResponse from 'axios';
import { USER_INFO, USER_TOKEN, HOTEL_ID } from '../utils/constant';
import CommonUtil from '../utils/common-utils';
import { LoginUser } from '../obj-type/obj-type';
import { LoadStatus } from '../utils/loadStatus';
import { AxiosInvoker } from '../utils/axiosInvoker';
import type { LoginParams } from '../obj-type/obj-type';
import { queryHotelId } from '../apis/hotel-room-api';
class AuthStore {
  rootStore: RootStore;
  loadStatus: LoadStatus = new LoadStatus();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, { rootStore: false });
  }

  getLoginUser = (): ?LoginUser => {
    const item = CommonUtil.getStorageItem(USER_INFO);
    if (item === null) {
      return null;
    }
    if (typeof item !== 'string') {
      return null;
    }
    return CommonUtil.toObject(item);
  };

  hasToken = (): boolean => {
    const token = CommonUtil.getStorageItem(USER_TOKEN);
    if (!token) {
      return false;
    }
    return token && token.trim().length > 0;
  };

  getUserToken = (): ?string => {
    return CommonUtil.getStorageItem(USER_TOKEN);
  };

  get loginUser(): ?LoginUser {
    let item = CommonUtil.getStorageItem(USER_INFO);
    if (!item) {
      return null;
    }
    return CommonUtil.toObject(item);
  }

  login = (data: LoginParams): Promise<AxiosResponse> => {
    return AxiosInvoker.invoke(
      this.loadStatus,
      () => MchUserApi.login(data),
      (resp: AxiosResponse) => {
        const { user, token } = resp.data;
        CommonUtil.setStorageItem(USER_TOKEN, token);
        CommonUtil.setStorageItem(USER_INFO, user);
        const { merchantId } = user;
        queryHotelId(merchantId).then(res => {
          console.log('hotel', res);
          CommonUtil.setStorageItem(HOTEL_ID, res.data);
        });
      }
    );
  };
  getHotelId() {
    let item = CommonUtil.getStorageItem(HOTEL_ID);
    if (!item) {
      return null;
    }
    return CommonUtil.toObject(item);
  }

  logout = () => {
    CommonUtil.deleteStorageItem(USER_TOKEN);
    CommonUtil.deleteStorageItem(USER_INFO);
    CommonUtil.deleteStorageItem(HOTEL_ID);
  };
}

export default AuthStore;
