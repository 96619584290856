import { createContext, useContext } from 'react';
import rootStore from '../store/RootStore';

const StoreContext = createContext(rootStore);

export function useStore() {
  return useContext(StoreContext);
}

export default StoreContext;
